import React, { useEffect, useRef, useState } from 'react'
import carousal from '../../Images/Carousal.svg'
import carousal_2 from '../../Images/Carousal_2.svg'
import Twig_cart from '../Twig_cart'

const Home_pg = (props) => {
  const {dark, setDark} = useState(false)
  return (
<>

<div ref={props.homeRef} className='fade-in w-full h-fit flex flex-col md:flex-row items-center justify-around pl-2 md:pl-20'>
    <div className='relative w-fit'>
        <section id='home_anime_1' className='font-semibold'>
            <p>Every</p>
            <p className='ml-16 md:ml-24'>Twig Counts</p>
        </section>
        <section id='home_anime_2' className='absolute top-[-16px] right-5 md:right-24'>
            <p>From Feed</p>
            <p className='ml-[80px] mt-2 md:ml-[102px] md:mt-8 text-nowrap'>To Fame</p>
        </section>
    </div>

    <Twig_cart />

</div>

</>
  )
}

export default Home_pg