import React from "react";
import facebook from "../Images/facebook.svg"
import instagram from "../Images/instagram.svg"
import twitter from "../Images/twitter.svg"
import linkedin from "../Images/linkedin.svg"
import twigyy_logo from '../Images/twigyy_logo.svg'

const Footer = () => {
  return (
    <div className="main-footer w-full text-white flex flex-col items-center gap-5 py-[65px] ">
      <div className="footer-menus flex flex-col md:flex-row gap-5 md:gap-0 justify-between  w-[70%]">
        <div className="footer-logo">
          <img src={twigyy_logo} alt="" className="md:w-[250px] md:mt-[30%]"/>
        </div>
        <div className="menu-1">
          <ul className="flex flex-col gap-5">
            <li>What we do?</li>
            <li>About Us</li>
            <li>Features</li>
            <li>Blog</li>
            <li>Security & Privacy</li>
          </ul>
        </div>
        <div className="menu-2">
          <ul className="flex flex-col gap-5">
            <li>Available On</li>
            <li>Android</li>
            <li>Twigyy Web</li>
          </ul>
        </div>
        <div className="menu-2">
          <ul className="flex flex-col gap-5">
            <li>Need Help?</li>
            <li>Contact Us</li>
            <li>Help Center</li>
          </ul>
        </div>
      </div>
      <hr className="w-[80%] mt-5"/>
      <div className="footer-socials flex gap-4">
        <img src={twitter} alt="" />
        <img src={facebook} alt="" />
        <img src={linkedin} alt="" />
        <img src={instagram} alt="" />
      </div>
    </div>
  );
};

export default Footer;
