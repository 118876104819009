import React, { useState, useEffect, useRef } from "react";
import SendFeedbackReply from "../Components/SendFeedbackReply";

const Feedbackpage = () => {
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    feedback: ''
  });

  const [textAreaWords, setTextAreaWords] = useState(0);
  const [error, setError] = useState('');
  const textareaRef = useRef(null);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const allowedDomains = ["gmail.com", "yahoo.com", "outlook.com"];
    const emailDomain = email.split('@')[1];

    if (!emailPattern.test(email)) {
      return "Invalid email format.";
    }

    if (!allowedDomains.includes(emailDomain)) {
      return `Allowed domains are: ${allowedDomains.join(", ")}`;
    }

    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      const errorMsg = validateEmail(value);
      setError(errorMsg);
    }

    if (name === "feedback") {
      setTextAreaWords(value.length);
      autoResizeTextarea(); // Auto-resize the textarea
    }
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    autoResizeTextarea(); // Ensure textarea resizes on initial render
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (error) {
      return;
    }

    console.log('Form submitted:', formData);
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('feedback', formData.feedback);

    const Sheet_Url = process.env.REACT_APP_SHEET_URL;
    try {
      await fetch(Sheet_Url, {
        method: 'POST',
        body: data,
        muteHttpExceptions: true,
      }).then(
        SendFeedbackReply(formData)
      );

      setFormData({
        name: '',
        email: '',
        feedback: '',
      });
      setError('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <form className="mb-10 p-10" onSubmit={handleSubmit}>
          <p className="font-medium text-[20px]"> Feedback</p>
          <p className="text-[15px] mt-5">
            We need your feedback to serve you better
          </p>
          <div className="flex flex-col">
            <input
              className="text-start p-2 align-top mt-4"
              type="text"
              name="name"
              id="name"
              value={formData.name}
              placeholder="Type your name"
              onChange={handleChange}
              required
            />
            <input
              className="text-start p-2 align-top mt-4"
              type="text"
              name="email"
              id="email"
              value={formData.email}
              placeholder="Type your email"
              onChange={handleChange}
              required
            />
            {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
            <textarea
              className="text-start p-2 align-top mt-4 h-[150px] min-h-[200px] w-full md:w-[500px]"
              name="feedback"
              id="feedback"
              typeof="text"
              value={formData.feedback}
              onChange={handleChange}
              maxLength="500"
              ref={textareaRef}
              required
            />
          </div>
          <div className="text-[12px] flex justify-between">
            <p>Write within 500 words</p>
            <p>{textAreaWords}/500</p>
          </div>
          <button
            className="bg-[#BDFA3C] w-[150px] h-[50px] rounded-lg mt-8 text-[18px] font-medium"
            type="submit"
            disabled={!!error}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Feedbackpage;
