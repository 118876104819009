import React from 'react'

const Tales = () => {
  return (
    <>
    <div className="flex flex-col gap-[20px] w-[500px]">
      <div className="font-medium">Tales</div>
      
      
    </div>
  </>
  )
}

export default Tales