import React from "react";

const Takes = () => {
  return (
    <>
      <div className="flex flex-col gap-[20px] w-[100%]">
        <div className="font-medium text-[30px]">Takes</div>
        <p>With Takes, you can record and edit short videos.</p>
        <div>
          <ul className="list-disc ">
            <p>How to upload Takes from computer:</p>

            <li className="mx-[35px]">
              Click on account(👤) from the the navigation bar to go to your
              profile
            </li>
            <li className="mx-[35px]">
            Click on “Create Takes”(✏️) button under the “Edit Profile” button

            </li>
            <li className="mx-[35px]">
            Click on “Select from computer” 
            </li>
            <li className="mx-[35px]">
            Select a video from your computer

            </li>
            <li className="mx-[35px]">
            Click on “next”

            </li>
            <li className="mx-[35px]">
            Click on the crop option available on the left bottom corner. Crop the video if you want to resize it then click on “next”

            </li>
            <li className="mx-[35px]">
            Add caption and click on “Done” 


            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Takes;
