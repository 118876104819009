import React from 'react'
import { Dropdown } from "flowbite-react";
import CodeIcon from '@mui/icons-material/Code';
import PersonIcon from '@mui/icons-material/Person';
import SupportIcon from '@mui/icons-material/Support';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Helpsidebar = (props) => {
  return (
    <>
    <div className='flex flex-col w-[30%] px-8'>

    <div className='font-medium text-[30px] cursor-pointer' onClick={() => props.Help_On('')}>Help Center</div>
    <div className='flex flex-col mb-10 mt-5 '>

    <Dropdown className='custom-dropdown' dismissOnClick={false} renderTrigger={() => <span className='cursor-pointer'>Twigyy Features <KeyboardArrowDownIcon/></span>}>
      <Dropdown.Item className='w-[50%]' onClick={() => props.Help_On('yourProfile')}>Your Profile</Dropdown.Item>
      <Dropdown.Item className='w-[50%]' onClick={() => props.Help_On('twig')}>Twigs</Dropdown.Item>
      <Dropdown.Item className='w-[50%]' onClick={() => props.Help_On('take')}>Takes</Dropdown.Item>
      <Dropdown.Item className='w-[50%]' onClick={() => props.Help_On('message')}>Message</Dropdown.Item>
      <Dropdown.Item className='w-[50%]' onClick={() => props.Help_On('search')}>Search</Dropdown.Item>
      <Dropdown.Item className='w-[50%]' onClick={() => props.Help_On('tales')}>Tales</Dropdown.Item>
    </Dropdown>

    {/* <Dropdown className='custom-dropdown' dismissOnClick={false} renderTrigger={() => <span className=''>Manage Your Account <KeyboardArrowDownIcon/></span>} >
    
      <Dropdown.Item>Signing  up and get started</Dropdown.Item>
      <Dropdown.Item>Adjust your account Settings</Dropdown.Item>
      <Dropdown.Item>Notification settings</Dropdown.Item>
      <Dropdown.Item>Adding account</Dropdown.Item>
      <Dropdown.Item>Accessibility</Dropdown.Item>
    </Dropdown>
    <Dropdown className='custom-dropdown' dismissOnClick={false} renderTrigger={() => <span className=''>Stay Safe <KeyboardArrowDownIcon/></span>}>
    
      <Dropdown.Item>Sharing photos safetly</Dropdown.Item>
      <Dropdown.Item>Safety tips</Dropdown.Item>
      <Dropdown.Item>Tips for parents</Dropdown.Item>
      <Dropdown.Item>Being your authentic self on Twigy</Dropdown.Item>
      <Dropdown.Item>Abuse and spam</Dropdown.Item>
      <Dropdown.Item>Self-injury</Dropdown.Item>
      <Dropdown.Item>About eating disorders</Dropdown.Item>
      <Dropdown.Item>Information for law enforcement</Dropdown.Item>
    </Dropdown> */}




    {/* <Dropdown className='custom-dropdown'  dismissOnClick={false} renderTrigger={() => <span className=''>Privacy,security and reporting<KeyboardArrowDownIcon/></span>}>
      <Dropdown.Item>Dashboard</Dropdown.Item>
      <Dropdown.Item>Settings</Dropdown.Item>
      <Dropdown.Item>Earnings</Dropdown.Item>
      <Dropdown.Item>Sign out</Dropdown.Item>
    </Dropdown>
    <Dropdown className='custom-dropdown'  dismissOnClick={false} renderTrigger={() => <span className=''>Terms and policies <KeyboardArrowDownIcon/></span>}>
    
      <Dropdown.Item>Dashboard</Dropdown.Item>
      <Dropdown.Item>Settings</Dropdown.Item>
      <Dropdown.Item>Earnings</Dropdown.Item>
      <Dropdown.Item>Sign out</Dropdown.Item>
    </Dropdown> */}

    </div>
    </div>

    </>
    
    
  )
}

export default Helpsidebar