import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../Styles/nav.css'
import twigyy_logo from '../Images/twigyy_logo.svg'

const Nav = () => {
    const location = useLocation();
    const [activeNav, setActiveNav] = useState(() => {
      return localStorage.getItem('activeNav') !== null
        ? parseInt(localStorage.getItem('activeNav'), 10)
        : 0;
    });

    const items = [
        {
            name:'Features',
            path:'/'
        },
        {
            name:'Help Center',
            path:'/help'
        },
        {
            name:'Blog',
            path:'/blog'
        },
        {
            name:'Feedback',
            path:'/feedback'
        },
        {
            name:'Twigyy Beta',
            path:'https://beta.twigyy.com/'
        }
    ]

    useEffect(() => {
        localStorage.setItem('activeNav', activeNav);
      }, [activeNav]);
    
      useEffect(() => {
        // Update activeNav based on the current path
        const currentPath = location.pathname;
        const activeIndex = items.findIndex(item => item.path === currentPath);
        setActiveNav(activeIndex !== -1 ? activeIndex : 0);
      }, [location, items]);

      const handleNavClick = (nav) => {
        setActiveNav(nav);
      };
    return (
        <div className='flex justify-between w-[95%] md:w-[80%] items-center mx-auto h-[60px] md:h-[80px]'>
            <div className='nav-logo'>
                <img className='md:w-[150px] w-0 ' src={twigyy_logo} alt="" />
            </div>
            <div className="nav-mid w-[100%] md:w-[50%]">
                <ul className='flex justify-around bg-gray-nav px-[5px] py-[10px] md:px-[23px] md:py-[15px] rounded-2xl text-center border-white border-2'>

                    {items.map((item, index) => (
                        <li key={index} className="w-fit flex flex-col items-center text-[8px] md:text-[18px]">
                            <Link
                                to={`${item.path}`}
                                onClick={() => handleNavClick(index)}
                                className={activeNav === index ? 'active' : ''}
                            >
                                {item.name}
                            </Link>
                            <div
                                className={`h-1 rounded-full ${activeNav === index ? 'underline' : ''}`}
                            ></div>
                        </li>
                    ))}
                    
                    {/* <li className='w-fit flex flex-col items-center'>
                        <a>Features</a>
                        <div className='h-1 rounded-full'></div>
                    </li>
                    <li className='w-fit flex flex-col items-center'>
                        <a>Help Center</a>
                        <div className='h-1 rounded-full'></div>                        
                    </li>
                    <li className='w-fit flex flex-col items-center'>
                        <a>Blog</a>
                        <div className='h-1 rounded-full'></div>                       
                    </li>
                    <li className='w-fit flex flex-col items-center'>
                        <a>Feedback</a>
                        <div className='h-1 rounded-full'></div>                   
                    </li>
                    <li className='w-fit flex flex-col items-center'>
                        <a>Twigyy Beta</a>
                        <div className='h-1 rounded-full'></div>                                     
                    </li> */}
                    {/* <li>Business</li> */}
                </ul>
            </div>
            <div className="nav-account hidden md:contents">
                <Link>Login/Signup</Link>
            </div>
        </div>
    )
}

export default Nav
