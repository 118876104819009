import React from "react";
import { Dropdown } from "flowbite-react";
import "../Styles/Privacy.css";
import Pdf from "../Pdf/_Privacy Policy.pdf";
const Privacy_Policy = () => {
  return (
    <>
      <div>
        <h1 className="text-4xl font-bold mt-5">Privacy Policy</h1>
        <p className="text-xl mt-5">
          Privacy policy of our app is a statement that outlines
          how the app collects, uses, and protects users' personal information.
          It explains what data is gathered such as profile details, posts, and
          interactions and how this information may be used to enhance user
          experience or improve services. The policy also describes users’
          rights regarding their data, including options to update, manage, or
          delete their information. Ultimately, the privacy policy serves as a
          transparent guide to help users understand their data's security and
          usage within the app.
        </p>
      </div>
      <a href={Pdf} target="_blank" className="text-blue-500">
        Learn More
      </a>

      {/* <div className="privacy_policy">
    <h1 className="text-xl font-semibold">Privacy Policy</h1>

<h2>What is the Privacy Policy and what does it cover?</h2>
<p>This Privacy Policy explains how we collect, use, and share your information. It also describes how long we keep your information and how we keep it safe when we transfer it. The Privacy Policy also lets you know your rights.</p>
<ul>
    <li>This policy applies to what we call Twiggy Products, or Products. Our Products include Twigyy.</li>
    <li>It also applies to Twiggy Quest if you log in with a Twigyy or Twiggy account.</li>
</ul>
<p>We at Twiggy want you to understand what information we collect, and how we use and share it. That's why we encourage you to read our Privacy Policy. This helps you to use Twiggy Products in the way that's right for you.</p>
<p>In the Privacy Policy, we explain how we collect, use, share, retain, and transfer information. We also let you know your rights. Each section of the Policy includes helpful examples and simpler language to make our practices easier to understand. We've also added links to resources where you can learn more about the privacy topics that interest you.</p>
<p>It's important to us that you know how to control your privacy, so we also show you where you can manage your information in the settings of the Twiggy Products you use. You can update your settings to shape your experience.</p>

<h2>What information do we collect?</h2>
<p>In this policy, we list the kinds of information that we collect. Here are some important ones. We collect:</p>
<ul>
    <li>The information that you give us when you sign up for our Products and create a profile, such as your email address or phone number.</li>
    <li>What you do on our Products. This includes what you click on or like, your posts, photos, and messages that you send. If you use end-to-end encrypted messaging, we can't read those messages unless users report them to us for review.</li>
    <li>Who your friends or followers are, and what they do on our Products.</li>
    <li>Information from the phone, computer, or tablet that you use our Products on, like what kind it is and what version of our app you're using.</li>
    <li>Information from partners about things you do both on and off our Products. This could include other websites you visit, apps you use, or online games you play.</li>
</ul>
<p>The information that we collect and process about you depends on how you use our products. For example, we collect different information if you sell furniture on Marketplace than if you post a reel. When you use our products, we collect some information about you even if you don’t have an account.</p>

<h3>Here's the information we collect:</h3>
<ul>
    <li><strong>Your activity and information that you provide:</strong> On our products, you can send messages, take photos and videos, buy or sell things, and much more. We collect your activity across our products and information that you provide, such as:
        <ul>
            <li>Content that you create, such as posts, comments, or audio.</li>
            <li>Content you provide through our camera feature or your camera roll settings, or through our voice-enabled features.</li>
        </ul>
    </li>
    <li><strong>Messages you send and receive:</strong> Including their content, subject to applicable law. We can't see the content of end-to-end encrypted messages unless users report them to us for review.</li>
    <li><strong>Data about content and messages:</strong> Information about the content itself, such as the location where a photo was taken or the date that a file was created.</li>
    <li><strong>Types of content, including ads, that you view or interact with:</strong> And how you interact with it.</li>
    <li><strong>Apps and features you use, and what actions you take in them:</strong>
        <ul>
            <li>What apps, posts, videos, ads, games, Shops, and other content you view or interact with on our Products.</li>
            <li>What features you access from our messaging products.</li>
            <li>When you use Social Plugins, Twigyy Login, in-app browser link history, or auto-fill.</li>
            <li>Information about websites that you visit or interact with when you use our in-app browser.</li>
        </ul>
    </li>
</ul>

<h3>About the in-app browser for Twigyy</h3>
<p>The in-app browser for Twigyy allows people to open web links within the Twigyy mobile app on iOS or Android. For example, if a customer sees your business' ad on the Twigyy app and taps on it, web links in your ad will open in the in-app browser.</p>
<p>The in-app browser enables your customers to seamlessly view and interact with your website without leaving the Twigyy app. This feature includes options to save, share, and access a "More info" feature displaying information about your business, such as business hours and contact details.</p>

<h3>Benefits for businesses and people</h3>
<p>At Twiggy, we use in-app browsers to enable safe, convenient, and reliable experiences for people and businesses. In-app browsers can help people seamlessly engage with content without waiting for another app to load or having to re-enter information. This also helps detect malicious activity, providing a safer experience for customers connecting with businesses.</p>

<h3>Frequently Asked Questions</h3>
<ul>
    <li><strong>Can people use their preferred browsers to open links?</strong> Yes, they can choose to open links in the system browser instead of the in-app browser.</li>
    <li><strong>Can I opt out of ad links opening in the in-app browser?</strong> By default, external links on Twigyy open in the in-app browser, but users can choose to open any links in their system browser.</li>
    <li><strong>What information can customers see about my business in the in-app browser?</strong> If a customer taps on an ad link in Twigyy, they can view information about your business within the in-app browser's "More info" feature, such as business name, category, contact details, and location.</li>
</ul>

<h3>Information with Special Protections</h3>
<p>You may choose to provide information with special protections, such as religious views, sexual orientation, political views, health, racial or ethnic origin, philosophical beliefs, or trade union membership. These types of information could have special protections under applicable laws.</p>

<h3>Friends, Followers, and Other Connections</h3>
<ul>
    <li>We collect information about friends, followers, groups, accounts, Twigyy Pages, and other users and communities you're connected to and interact with. This includes how you interact with them and which ones you interact with most frequently.</li>
    <li><strong>Information we collect about contacts:</strong> We also collect your contacts' information, such as names, email addresses, or phone numbers, if you choose to sync an address book.</li>
    <li><strong>Information inferred from others' activity:</strong> For example, we may suggest a friend to you if you and another user both appear on a contact list that someone else uploads.</li>
</ul>
<h2>App, Browser, and Device Information</h2>
    <p>We collect and receive information from and about the different devices you use and how you use them.</p>
    
    <h3>Device information we collect and receive includes:</h3>
    <p>The device and software you're using, along with other characteristics. We collect details such as:</p>
    <ul>
        <li>The type of device</li>
        <li>Operating system details</li>
        <li>Hardware and software specifications</li>
        <li>Brand and model</li>
        <li>Battery level</li>
        <li>Signal strength</li>
        <li>Available storage</li>
        <li>Browser type</li>
        <li>App and file names and types</li>
        <li>Plugins</li>
        <li>What you're doing on your device, such as whether our app is in the foreground or if your mouse is moving (helps distinguish humans from bots)</li>
        <li>Identifiers that distinguish your device, including Family device IDs, mobile advertiser ID, game/app account IDs, and other identifiers associated with the device or account</li>
    </ul>

    <h3>Device Signals</h3>
    <p>We collect signals from your device, such as:</p>
    <ul>
        <li>GPS location</li>
        <li>Bluetooth signals</li>
        <li>Nearby Wi-Fi access points</li>
        <li>Beacons and cell towers</li>
    </ul>
    
    <h3>Information from Device Settings</h3>
    <p>We collect information that you've shared with us through device settings, such as:</p>
    <ul>
        <li>GPS location</li>
        <li>Camera access</li>
        <li>Photos and related metadata, including date and time (used to provide reminders for uploading new photos)</li>
    </ul>

    <h3>Network and Connection Information</h3>
    <p>We collect information about the network that your device connects to, including:</p>
    <ul>
        <li>Mobile operator or ISP</li>
        <li>Language</li>
        <li>Time zone</li>
        <li>Mobile phone number</li>
        <li>IP address</li>
        <li>Connection and download speed</li>
        <li>Network capability</li>
        <li>Details about nearby or networked devices</li>
        <li>Wi-Fi hotspots you connect to using our Products</li>
    </ul>
    <p>We collect this information to enhance your experience, such as enabling you to control a video stream on your TV using your phone if both devices are on the same network.</p>

    <h3>Location-Related Information</h3>
    <p>We may receive location-related information even if location services are turned off on your device. This includes:</p>
    <ul>
        <li><strong>IP address:</strong> We use IP addresses to estimate your general location, or specific location if necessary to ensure your security.</li>
        <li><strong>Your activity on our Products:</strong> For example, check-ins and events.</li>
        <li><strong>Directly provided location information:</strong> For example, your current city on your profile or your address on Marketplace.</li>
    </ul>
    <p>We use location-related information to provide, personalize, and improve our Products, including ads. For instance:</p>
    <ul>
        <li>If you enable Location Services, we may use GPS information to show you relevant public Wi-Fi networks or local businesses. If Location Services are later disabled, we use IP address data to estimate your general location.</li>
    </ul>
    <p>We also use location-related information to detect suspicious activity and keep your account secure.</p>

    <h3>Performance Information</h3>
    <p>We collect data on how our Products perform on your device to diagnose and fix issues. This includes information such as:</p>
    <ul>
        <li>How long the app was running</li>
        <li>Device model</li>
        <li>Performance and diagnostic data</li>
    </ul>

    <h3>Cookies and Similar Technologies</h3>
    <p>Cookies are small pieces of text stored in web browsers. We use cookies and similar technologies, including identifiers associated with your device and software, Social Plugins, and the Twiggy Pixel, to:</p>
    <ul>
        <li>Provide, protect, and improve our Products</li>
        <li>Personalize content</li>
        <li>Tailor and measure ads</li>
        <li>Provide a safer experience</li>
    </ul>
    <p>We collect information from cookies stored on your device, such as cookie IDs and settings.</p>
    </div> */}
    </>
    // <>
    // <div className="text-[24px] font-medium">Privacy Policy</div>
    //   <div className="text-[20px] ">
    //     We updated our Privacy Policy on [Date].{" "}
    //   </div>
    //   <div>
    //     <div>
    //       <Dropdown
    //         className="flex block bg-transparent w-[50%]"
    //         label={
    //           <p className="text-[20px] font-medium">
    //             What is the Privacy Policy and what does it cover?
    //           </p>
    //         }
    //         inline
    //       >
    //         <Dropdown.Item className=" text-[15px] bg-transparent w-[100%] p-2 text-left ">
    //           122421354344We at Meta want you to understand what information we
    //           collect, and how we use and share it. That's why we encourage you
    //           to read our Privacy Policy. This helps you use in the way that's
    //           right for you. In the Privacy Policy, we explain how we collect,
    //           use, share, retain and transfer information. We also let you know
    //           your rights. Each section of the Policy includes helpful examples
    //           and simpler language to make our practices easier to understand.
    //           We've also added links to resources where you can learn more about
    //           the privacy topics that interest you. It's important to us that
    //           you know how to control your privacy, so we also show you where
    //           you can manage your information in the settings of the Meta
    //           Products you use. You can to shape your experience.
    //         </Dropdown.Item>
    //       </Dropdown>
    //     </div>
    //     <div>
    //       <Dropdown
    //         className="flex block bg-transparent w-[50%]"
    //         label={
    //           <p className="text-[20px] font-medium">
    //             What information do we collect?
    //           </p>
    //         }
    //         inline
    //       >
    //         <Dropdown.Item className=" text-[15px] bg-transparent w-[100%]  text-left ">
    //           We at Meta want you to understand what information we collect, and
    //           how we use and share it. That's why we encourage you to read our
    //           Privacy Policy. This helps you use in the way that's right for
    //           you. In the Privacy Policy, we explain how we collect, use, share,
    //           retain and transfer information. We also let you know your rights.
    //           Each section of the Policy includes helpful examples and simpler
    //           language to make our practices easier to understand. We've also
    //           added links to resources where you can learn more about the
    //           privacy topics that interest you. It's important to us that you
    //           know how to control your privacy, so we also show you where you
    //           can manage your information in the settings of the Meta Products
    //           you use. You can to shape your experience.
    //         </Dropdown.Item>
    //       </Dropdown>
    //     </div>
    //   </div>
    // </>
  );
};

export default Privacy_Policy;
