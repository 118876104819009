import React, { useEffect, useRef, useState } from "react";
import home from "../Images/SideBar/home.svg";
import message from "../Images/SideBar/message.svg";
import search from "../Images/SideBar/search.svg";
import take from "../Images/SideBar/takes.svg";
import tale from "../Images/SideBar/tales.svg";

const SideBar = (props) => {
  const [homeState, setHomeState] = useState(true);
  const [messageState, setMessageState] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [takeState, setTakeState] = useState(false);
  const [taleState, setTaleState] = useState(false);

  const scrollToSection = (sectionRef, setState, state) => {
    if (props.mainRef.current && sectionRef.current) {
      const contentTop = props.mainRef.current.getBoundingClientRect().top;
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      props.mainRef.current.scrollTo({
        top: sectionTop - contentTop + props.mainRef.current.scrollTop - 30,
        behavior: "smooth",
      });
    }
    setHomeState(false);
    setMessageState(false);
    setSearchState(false);
    setTakeState(false);
    setTaleState(false);

    setState(true);
  };

  const handleScroll = () => {
    if (props.mainRef.current) {
      const contentTop = props.mainRef.current.getBoundingClientRect().top;

      const sections = [
        { ref: props.homeRef, setState: setHomeState },
        { ref: props.messageRef, setState: setMessageState },
        { ref: props.searchRef, setState: setSearchState },
        { ref: props.takeRef, setState: setTakeState },
        { ref: props.taleRef, setState: setTaleState }
      ];

      sections.forEach(({ ref, setState }) => {
        if (ref.current) {
          const sectionTop = ref.current.getBoundingClientRect().top - contentTop -100;
          const sectionBottom = sectionTop + ref.current.getBoundingClientRect().height;

          if (sectionTop <= 30 && sectionBottom > 30) {
            setHomeState(false);
            setMessageState(false);
            setSearchState(false);
            setTakeState(false);
            setTaleState(false);
            setState(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    const content = props.mainRef.current;
    content.addEventListener('scroll', handleScroll);

    return () => {
      content.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="w-[10%] h-[10vh] flex items-center justify-center sticky top-[65vh] md:top-[35%] left-[45%] md:left-0 z-40 ">
        <div className="flex flex-row md:flex-col gap-6 bg-[#38424B] rounded-[60px] py-3 px-2 md:py-8 md:px-3 ">

          <button
            onClick={() => scrollToSection(props.homeRef, setHomeState)}
            className={`flex items-center justify-center w-[35px] h-[35px] md:w-[60px] md:h-[60px] rounded-full ${
              homeState ? "bg-[#BDFA3C]" : ""
            }`}
          >
            {" "}
            <img
              className={`w-5 h-5 md:w-10 md:h-10 ${homeState ? "invert" : ""}`}
              src={home}
            />{" "}
          </button>

          <button
            onClick={() => scrollToSection(props.messageRef, setMessageState)}
            className={`flex items-center justify-center w-[35px] h-[35px] md:w-[60px] md:h-[60px] rounded-full ${
              messageState ? "bg-[#BDFA3C]" : ""
            }`}
          >
            {" "}
            <img
              className={`w-5 h-5 md:w-10 md:h-10 ${messageState ? "invert" : ""}`}
              src={message}
            />{" "}
          </button>

          <button
            onClick={() => scrollToSection(props.searchRef, setSearchState)}
            className={`flex items-center justify-center w-[35px] h-[35px] md:w-[60px] md:h-[60px] rounded-full ${
              searchState ? "bg-[#BDFA3C]" : ""
            }`}
          >
            {" "}
            <img
              className={`w-5 h-5 md:w-10 md:h-10 ${searchState ? "invert" : ""}`}
              src={search}
            />{" "}
          </button>

          <button
            onClick={() => scrollToSection(props.takeRef, setTakeState)}
            className={`flex items-center justify-center w-[35px] h-[35px] md:w-[60px] md:h-[60px] rounded-full ${
              takeState ? "bg-[#BDFA3C]" : ""
            }`}
          >
            {" "}
            <img
              className={`w-5 h-5 md:w-10 md:h-10 ${takeState ? "invert" : ""}`}
              src={take}
            />{" "}
          </button>

          <button
            onClick={() => scrollToSection(props.taleRef, setTaleState)}
            className={`flex items-center justify-center w-[35px] h-[35px] md:w-[60px] md:h-[60px] rounded-full ${
              taleState ? "bg-[#BDFA3C]" : ""
            }`}
          >
            {" "}
            <img
              className={`w-5 h-5 md:w-10 md:h-10 ${taleState ? "invert" : ""}`}
              src={tale}
            />{" "}
          </button>

        </div>
      </div>
    </>
  );
};

export default SideBar;
