import React, { useEffect, useRef } from 'react'
import '../Styles/Page_2.css'

import Home_pg from './Main scroll animation/Home_pg'
import Message_pg from './Main scroll animation/Message_pg'
import Search_pg from './Main scroll animation/Search_pg'
import Takes_pg from './Main scroll animation/Takes_pg'
import Tales_pg from './Main scroll animation/Tales_pg'
import SideBar from './SideBar'

const Page_2 = () => {
  const divRef = useRef(null);

  const homeRef = useRef(null)
  const messageRef = useRef(null)
  const searchRef = useRef(null)
  const takeRef = useRef(null)
  const taleRef = useRef(null)

  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();

      const { deltaY } = event;
      const currentScroll = divRef.current.scrollTop;
      const newScroll = currentScroll + deltaY * 1; // Adjust the multiplier to control scroll speed

      divRef.current.scrollTo({
        top: newScroll,
        behavior: 'smooth'
      });
    };

    const divElement = divRef.current;
    divElement.addEventListener('wheel', handleScroll);

    return () => {
      divElement.removeEventListener('wheel', handleScroll);
    };
  }, []);
  return (
<>
<div className='scroll_pg pt-20 z-10' ref={divRef}>
    <SideBar homeRef={homeRef} messageRef={messageRef} searchRef={searchRef} takeRef={takeRef} taleRef={taleRef} mainRef={divRef} />
    <Home_pg homeRef={homeRef} />
    <Message_pg messageRef={messageRef} />
    <Search_pg searchRef={searchRef} />
    <Takes_pg takeRef={takeRef} />
    <Tales_pg taleRef={taleRef} />

    {/* Empty Div */}
    <div className='h-40 w-2 md:h-20'></div> 
</div>

</>
  )
}

export default Page_2