import React, { useState } from "react";
import three_dot from "../Images/Three_dot.svg";
import love_like from '../Images/love_like.svg'
import comment_icon from '../Images/Comment.svg'
import share_icon from '../Images/share.svg'
import save_icon from '../Images/save.svg'
import profile from '../Images/twig_profile.jpg'
import Slider from '../Components/Main scroll animation/Slider'

const images = [
  require("../Images/photo_1.jpg"),
  require("../Images/photo_2.jpg"),
  require("../Images/photo_3.jpg"),
];

const Twig_cart = () => {
  const [dark, setDark] = useState(false);

  return (
    <>
  <div className="flex flex-col items-center h-[300px] md:h-fit scale-[.5] md:scale-100">
    <label  class="inline-flex items-center mb-5 cursor-pointer">
      <input onClick={() => setDark(!dark)} type="checkbox" value="" class="sr-only peer"/>
      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </label>

    <div className={`h-[500px] w-[550px] rounded-tl-[30px] rounded-br-[30px] p-4 ${dark ? 'bg-black text-white' : 'bg-white text-black'} relative`}>
      <div className="flex items-center w-full">
        <img
          className="rounded-[15px] w-[50px] h-[50px] object-cover"
          src={profile}
        />
        <div className="ml-2">
          <p>Ginny Albert</p>
          <p>1 min ago</p>
        </div>
        <img className={`ml-auto w-[40px] ${dark ? 'invert-0' : 'invert'}`} src={three_dot} />
      </div>

      <div className="twig_carousal flex w-full h-[300px] mt-4">
        <Slider/>
      </div>

      <div className="flex flex-col h-[100px] justify-between">
        <div className="flex w-full justify-between mt-6">
          <p>Good Day</p>
          <img className="h-[30px]" src={save_icon} />
        </div>

        <div className="flex items-center w-full">
          <button>
            <img className="h-[30px]" src={love_like} />
          </button>
          <p className="mr-4 ml-2">12</p>
          <button>
            <img className="h-[30px]" src={comment_icon} />
          </button>
          <p className="mr-4 ml-2">22</p>
          <button>
            <img className="h-[30px]" src={share_icon} />
          </button>
          <p className="mr-4 ml-2">234</p>
        </div>
        
      </div>
    </div>
</div>
    </>
  );
};

export default Twig_cart;
