import React, { useEffect, useRef } from 'react'
import takes from '../../Images/takes.svg'
import takes_icon from '../../Images/takes_icon.svg'
import Take_video from '../Take_video'

const Takes_pg = (props) => {
  return (
<>

<div ref={props.takeRef} className='fade-in w-full h-[100%] flex flex-col md:flex-row items-center justify-around pl-2 md:pl-20'>

    <div className='w-full md:w-fit'>
        <section className='takes_text_1 flex flex-col justify-center items-center'>
            <div className='flex'>
                <span id='take_rl' className='overflow-hidden'>
                    <p className='text_visible'>Sh</p>
                </span>
                <img className='w-5 md:w-fit mt-2 md:mt-4' src={takes_icon} />
                <span id='take_lr' className='overflow-hidden'>
                    <p className='text_visible'>rt takes</p>
                </span>
            </div>
            <div id='fall_down' className='translate_top overflow-hidden'>
                <p className='text_visible md:ml-[120px] w-fit'>Big Impact</p>
            </div>
        </section>
        <section id='popUp_take' className='op takes_text_2 ml-8 md:ml-[300px] mt-10 flex flex-row md:flex-col'>
            <p>Unleash your creativity </p>
            <p className='ml-2 md:ml-[200px]'>in seconds</p>
        </section>
    </div>
    {/* <img className='h-[90%]' src={takes} /> */}
    <Take_video/>
</div>

</>
)
}

export default Takes_pg