import React from "react";

const Message = () => {
  return (
    <>
      <div className="flex flex-col gap-[20px] w-[100%]">
        <div className="font-medium text-[30px]">Messaging</div>
        <div>
          <p>
            Messaging on Twigyy allows you to instantly connect with friends,
            individually or in groups, through chats and calls.
          </p>
        </div>
        <p>Learn how to start, view and manage conversations:</p>
        <div>
          <ul className="list-disc ">
            <p>Check previous or new messages from computer:</p>

            <li className="mx-[35px]">
              Click on account(🖨️) from the the navigation bar to go to your
              profile
            </li>
            <li className="mx-[35px]">
              The left side panel shows your previous or new contacts with your
              friends on twigyy
            </li>
            <li className="mx-[35px]">
              Click on any of them to check or send message
            </li>
          </ul>
          <ul className="list-disc ">
            <p>Find to start a conversation from computer:</p>

            <li className="mx-[35px]">
              Click on account(🖨️) from the the navigation bar to go to your
              profile
            </li>
            <li className="mx-[35px]">
              Click on “Send Messages” or the plus button(+) on the top right
              corner of the left side panel.
            </li>
            <li className="mx-[35px]">Find the person you want to chat.</li>
            <li className="mx-[35px]">
              Click on that account and start your conversation.
            </li>
          </ul>
          <ul className="list-disc ">
            <p>How to send massage from computer:</p>

            <li className="mx-[35px]">
              Click on account(🖨️) from the the navigation bar to go to your
              profile
            </li>
            <li className="mx-[35px]">
              The left side panel shows your previous or new contacts with your
              friends on twigyy.
            </li>
            <li className="mx-[35px]">
              Click on “Send Messages” or the plus button(+) on the top right
              corner of the left side panel.
            </li>
            <li className="mx-[35px]">Find the person you want to chat.</li>
            <li className="mx-[35px]">Click on that account.</li>
            <li className="mx-[35px]">
              Click on the text input field on the bottom of the page to write
              your message.
            </li>
            <li className="mx-[35px]">Click on the emoji(😀) to add emojis.</li>
            <li className="mx-[35px]">
              Click on send(▶️) button to send the message.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Message;
