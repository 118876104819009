import React, { useEffect, useState } from "react";
import love_like from '../Images/love_like.svg'
import comment_icon from '../Images/comment_icon.svg'

const Tale_video = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = 3;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
    }, 3000);
    return () => clearInterval(interval);
  }, [totalItems]);

  const handleRadioChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className={`tale_container ${currentIndex === 1 ? "blue" : ""}`}>
      <input
        type="radio"
        name="tale_slider"
        id="tale_item-1"
        checked={currentIndex === 0}
        onChange={() => handleRadioChange(0)}
      />
      <input
        type="radio"
        name="tale_slider"
        id="tale_item-2"
        checked={currentIndex === 1}
        onChange={() => handleRadioChange(1)}
      />
      <input
        type="radio"
        name="tale_slider"
        id="tale_item-3"
        checked={currentIndex === 2}
        onChange={() => handleRadioChange(2)}
      />

      <div className="tale_cards">
        <img className="absolute top-10 left-[0px] md:left-[150px] rotate-[-45deg]" src={love_like} />
        <img className="absolute top-8 right-[-120px] md:right-[145px]" src={comment_icon} />
        <label className="tale_card" htmlFor="tale_item-1" id="tale-1">
          <img src={require("../Images/tale_1.jpg")} alt="song" />
        </label>
        <label className="tale_card" htmlFor="tale_item-2" id="tale-2">
          <img src={require("../Images/tale_2.jpg")} alt="song" />
        </label>
        <label className="tale_card" htmlFor="tale_item-3" id="tale-3">
          <img src={require("../Images/tale_3.jpg")} alt="song" />
        </label>
      </div>
    </div>
  );
};

export default Tale_video;
