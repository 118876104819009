import React from "react";
import { Dropdown } from "flowbite-react";
import "../Styles/DropDown.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Yourprofile = () => {
  return (
    <>
      <div className="flex flex-col gap-[20px] w-[100%] px-10">
        <div className="font-medium">Your Profile</div>
        <div>
          <p>
            Your profile is where you can find your photos, videos and settings
            on Twigyy. Here, you can view<br/> what you've shared, see a list of the
            people that you're following and who's following you.
          </p>
        </div>
        <div>Learn How to:</div>
        <div>
          <Dropdown
            className="custom-dropdown"
            dismissOnClick={false}
            renderTrigger={() => (
              <span className="">
                Edit your profile <KeyboardArrowDownIcon />{" "}
              </span>
            )}
          >
            <Dropdown.Item className=" text-[15px] bg-transparent w-[80%] p-2 text-left ">
              <div>
                  
                <ul className="list-disc ">
                <p className="mx-[35px]">Add a bio to your profile.</p>
                  <p>
                    You can write a bio of up to 150 characters on your Twigyy
                    profile. Please keep in mind that anyone can see your bio.
                  </p>
                  <p>To add or edit your bio:</p>
                  <li className="mx-[35px]">
                    Click on account(👤) from the the navigation bar to go to
                    your profile or just go to settings by clicking settings(⚙️)
                    from the navigation bar to directly edit your profile.
                  </li>
                  <li className="mx-[35px]">
                    Click on Edit profile(if you are going through your account
                    page).
                  </li>
                  <li className="mx-[35px]">
                    Write your bio in the bio section.
                  </li>
                  <li className="mx-[35px]">
                    Click Done to save your changes.
                  </li>
                </ul>
                <ul className="list-disc ">
                  <p>To change your Userame:</p>
                  <li className="mx-[35px]">
                    Click on account(👤) from the the navigation bar to go to
                    your profile or just go to settings by clicking settings(⚙️)
                    from the navigation bar to directly edit your profile.
                  </li>
                  <li className="mx-[35px]">
                    Click on Edit profile(if you are going through your account
                    page).
                  </li>
                  <li className="mx-[35px]">
                    Change your Username in the Username section.
                  </li>
                  <li className="mx-[35px]">
                    Click Done to save your changes.
                  </li>
                </ul>
                <p>Keep in mind</p>
                <p>
                  If your account reaches a lot of people, your username change
                  may need to be reviewed. If so, you'll be notified when we've
                  completed a review. If not, your username will change
                  immediately if available.Your followers may be notified of
                  your username change. Some profile information isn't visible
                  to anyone but you. This includes your email address, phone
                  number and gender.
                </p>
                <ul className="list-disc ">
                  <p>To change your Profile and cover picture:</p>
                  <li className="mx-[35px]">
                    Click on account(👤) from the the navigation bar to go to
                    your profile or just go to settings by clicking settings(⚙️)
                    from the navigation bar to directly edit your profile.
                  </li>
                  <li className="mx-[35px]">
                    Click on Edit profile(if you are going through your account
                    page).
                  </li>
                  <li className="mx-[35px]">
                    Click to Change your Profile or Cover picture(📷) from the
                    Profile and Cover picture section on the top of the page.
                  </li>
                  <li className="mx-[35px]">Select your desired pictures.</li>
                  <li className="mx-[35px]">
                    Click Done to save your changes.
                  </li>
                </ul>
                <ul className="list-disc ">
                  <p>To change your Profile information:</p>
                  <li className="mx-[35px]">
                    Click on account(👤) from the the navigation bar to go to
                    your profile or just go to settings by clicking settings(⚙️)
                    from the navigation bar to directly edit your profile.
                  </li>
                  <li className="mx-[35px]">
                    Click on Edit profile(if you are going through your account
                    page).
                  </li>
                  <li className="mx-[35px]">Do changes as you want.</li>
                  <li className="mx-[35px]">
                    Click Done to save your changes.
                  </li>
                </ul>
                <p>Make your account private</p>
                <p>
                  Note: If you are under 16 when you sign up for an Twigyy
                  account, you'll have the option to choose between a public or
                  private account, but private will be selected by default.If
                  you're over 16, your Twigyy account is public by default and
                  you can choose to make your account private at any time. Learn
                  more about how to make your account private below.
                </p>
                <ul className="list-disc ">
                  <p>To change your profile privacy:</p>
                  <li className="mx-[35px]">
                    Click on account(👤) from the the navigation bar to go to
                    your profile or just go to settings by clicking settings(⚙️)
                    from the navigation bar to directly edit your profile.
                  </li>
                  <li className="mx-[35px]">
                    Click on Lock Your Profile under Edit profile(if you are
                    going through your account page).
                  </li>
                  <li className="mx-[35px]">
                    Click next to account privacy under privacy section to make
                    your account private(if you are going through your Settings
                    page).
                  </li>
                  <li className="mx-[35px]">Click Switch to private.</li>
                </ul>
                <p className="text-[20px] font-semibold">
                  The differences between public and private accounts on Twigyy:
                </p>
                <table className="border-collapse border border-black">
                  <tr>
                    <th className="border border-black"></th>
                    <th className="border border-black font-bold">Public</th>
                    <th className="border border-black font-bold">Private</th>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can follow you?</td>
                    <td className="border border-black"> Anyone on Twigyy</td>
                    <td className="border border-black"> Only people you approve</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can see public accounts you follow and who follow you?</td>
                    <td className="border border-black">Anyone on Twigyy</td>
                    <td className="border border-black">Anyone on Twigyy</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can see private accounts you follow and private accounts that follow you?</td>
                    <td className="border border-black">Anyone on Twigyy</td>
                    <td className="border border-black">Your followers</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can see your photos and videos on your profile or in feed?</td>
                    <td className="border border-black">Anyone on Twigyy</td>
                    <td className="border border-black">Your followers</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can see your photos and videos in search results, Explore, and hashtag and location pages?</td>
                    <td className="border border-black">Anyone on Twigyy</td>
                    <td className="border border-black">No one</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can see your profile information, including your profile photo, name, username and bio?</td>
                    <td className="border border-black">Anyone on or off Twigyy</td>
                    <td className="border border-black">Anyone on or off Twigyy</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can share your photos and videos with other people on Twigyy and see what’s shared?</td>
                    <td className="border border-black">Anyone on Twigyy, unless you update your settings</td>
                    <td className="border border-black">Your followers, unless you update your settings</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can download your reel?</td>
                    <td className="border border-black">Anyone on Twigyy, unless you update your settings</td>
                    <td className="border border-black">No one</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can remix your videos?</td>
                    <td className="border border-black">Anyone on Twigyy, unless you update your settings</td>
                    <td className="border border-black">No one</td>
                  </tr>
                  <tr>
                    <td className="border border-black">Who can embed your photos and videos off Twigyy?</td>
                    <td className="border border-black">Anyone on or off Twigyy</td>
                    <td className="border border-black">No one</td>
                  </tr>
                </table>
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Yourprofile;
