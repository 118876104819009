import React, { useEffect, useRef } from 'react'
import Message_animation from '../Message_animation'


const Message_pg = (props) => {
  return (
<>

<div ref={props.messageRef} className='fade-in w-full h-[100%] flex flex-col md:flex-row items-center justify-around pl-2 md:pl-20'>
    <div className='w-fit'>
        <section id='msg_1' className='op font-semibold'>
            <p>Connect Instantly</p>
            <p className=''>Chat Freely</p>
        </section>
        <section id='msg_2' className='op ml-[100px] mt-2 md:ml-[300px] md:mt-10'>
            <p>Message on your own way</p>
            <p className='mt-2'>Anytime Anywhere</p>
        </section>
    </div>

    <Message_animation/>

</div>

</>
)
}

export default Message_pg