import React, { useEffect, useState } from 'react'
import action_sec from '../Images/take_action_sec.svg'

const Take_video = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalItems = 3;
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
      }, 3000);
      return () => clearInterval(interval);
    }, [totalItems]);
  
    const handleVideoChange = (index) => {
      setCurrentIndex(index);
    };
  
return (
<div className={`video_container ${currentIndex === 1 ? 'blue' : ''}`}>
    <input
        type="radio"
        name="video_slider"
        id="video_item-1"
        checked={currentIndex === 0}
        onChange={() => handleVideoChange(0)}
    />
    <input
        type="radio"
        name="video_slider"
        id="video_item-2"
        checked={currentIndex === 1}
        onChange={() => handleVideoChange(1)}
    />
    <input
        type="radio"
        name="video_slider"
        id="video_item-3"
        checked={currentIndex === 2}
        onChange={() => handleVideoChange(2)}
    />
    
    <div className="video_cards">
        <img className='absolute top-[25%] md:top-[40%] left-[-70px] md:left-0 w-5 md:w-fit' src={action_sec} />
        <label className="video_card" htmlFor="video_item-1" id="video-1">
            <video
            src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/landing%20page%2Ftakes%2Ftake_1.mp4?alt=media&token=0be663f4-5958-4e36-8881-1040ae7d8beb"
            alt="song"
            muted
            autoPlay
            loop
            />
        </label>
        <label className="video_card" htmlFor="video_item-2" id="video-2">
        <video
            src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/landing%20page%2Ftakes%2Ftake_2.mp4?alt=media&token=e69f6880-a3df-4a7a-bad5-5c4bcff2db40"
            alt="song"
            muted
            autoPlay
            loop
            />
        </label>
        <label className="video_card" htmlFor="video_item-3" id="video-3">
        <video
            src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/landing%20page%2Ftakes%2Ftake_3.mp4?alt=media&token=ecfb8a65-7d40-42ab-84df-6cde20d99620"
            alt="song"
            muted
            autoPlay
            loop
            />
        </label>
    </div>
</div>
)
}

export default Take_video